<template>
  <div class="flex flex-col gap-8 md:mx-44 mx-8 my-2">
    <!-- <BreadcrumbFAQ page="Merchant" /> -->
    <div class="flex flex-col gap-2 mx-4">
      <div class="flex flex-col gap-2 md:mr-80">
        <div
          v-for="(faq, index) in FAQs"
          :key="index"
          @click="toggleShowFAQ(index)"
        >
          <div
            class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
            :class="[faq.show ? 'text-green-1' : '']"
          >
            <font-awesome-icon
              icon="fa-solid fa-circle-chevron-up"
              v-if="faq.show"
            />
            <font-awesome-icon icon="fa-solid fa-circle-chevron-down" v-else />
            <p>{{ faq.question }}</p>
          </div>
          <div v-if="faq.show">
            <ol class="ml-12 list-decimal" v-if="faq.answer.length > 1">
              <li v-for="answer in faq.answer">{{ answer }}</li>
            </ol>
            <p class="ml-8" v-else>
              {{ faq.answer[0] }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import BreadcrumbFAQ from "../../components/BreadcrumbFAQ.vue";

export default {
  name: "FaqMerchant",
  // components: { BreadcrumbFAQ },
  data() {
    return {
      FAQs: [
        {
          show: false,
          question: "How to become a partner of Tripwe Indonesia?",
          answer: [
            "To become a merchant, you can fill out the form available on the Tripwe.com website or contact the BD Tripwe team via instagram or wa",
          ],
        },
        {
          show: false,
          question:
            "If I have received verification and become a partner of Tripwe Indonesia, what should I do next?",
          answer: [
            "If you have received verification to become a partner, the next step is to activate your account by logging into the Tripwe application and changing the password as desired and activating TripwePay by entering your PIN.",
          ],
        },
        {
          show: false,
          question: "Can I determine the opening and closing of my own shop?",
          answer: [
            "Yes, Admin can determine the schedule of holidays from the store in the Edit Store section.",
          ],
        },
        {
          show: false,
          question: "How do I make a package in the Tripwe app?",
          answer: [
            "Click Package according to your type of attraction.",
            "Click the + (PLUS) icon",
            "Complete the package data information according to the page column listed",
            "Click Submit",
          ],
        },
        {
          show: false,
          question: "Can I edit the package I made in the Tripwe app?",
          answer: ["Yes, Admin can edit packages made in the Tripwe app"],
        },
        {
          show: false,
          question:
            "If someone orders a package that the admin sells, what things should the admin prepare?",
          answer: [
            "Ensure the availability of the ordered package, and remind the user to show the QR when arriving at the meeting point location.",
          ],
        },
        {
          show: false,
          question: "What is check-in in the Tripwe application?",
          answer: [
            "Check-in is a QR Code scanning activity for customer orders.",
          ],
        },
        {
          show: false,
          question:
            "After the consumer checks-in, can the admin immediately complete the package?",
          answer: [
            "Yes, the admin can immediately complete the order after the user checks in.",
          ],
        },
        {
          show: false,
          question:
            "After the order package is completed where will the funds be paid?",
          answer: [
            "When the order is entered, the funds will be stored in the Hold Amount. Once the order is completed, the funds will be transferred to the store admin's TripwePay balance.",
          ],
        },
        {
          show: false,
          question:
            "Can the funds available in TripwePay Admin be fully disbursed?",
          answer: [
            "If there is an incoming order, the admin can check periodically on the Admin page then click Order Report.",
          ],
        },
        {
          show: false,
          question: "If there is an order, where is the notification?",
          answer: [
            "If there is an incoming order, the admin can check periodically on the Admin page then click Order Report.",
          ],
        },
      ],
    };
  },
  created() {
    if (this.$route.params.id == 0) {
      this.openAccordionWithUrl(0);
    } else if (this.$route.params.id == 1) {
      this.openAccordionWithUrl(1);
    } else if (this.$route.params.id == 2) {
      this.openAccordionWithUrl(2);
    } else if (this.$route.params.id == 3) {
      this.openAccordionWithUrl(3);
    } else if (this.$route.params.id == 4) {
      this.openAccordionWithUrl(4);
    } else if (this.$route.params.id == 5) {
      this.openAccordionWithUrl(5);
    } else if (this.$route.params.id == 6) {
      this.openAccordionWithUrl(6);
    } else if (this.$route.params.id == 7) {
      this.openAccordionWithUrl(7);
    } else if (this.$route.params.id == 8) {
      this.openAccordionWithUrl(8);
    } else if (this.$route.params.id == 9) {
      this.openAccordionWithUrl(9);
    } else if (this.$route.params.id == 10) {
      this.openAccordionWithUrl(10);
    }
  },
  methods: {
    toggleShowFAQ(index) {
      this.FAQs[index].show = !this.FAQs[index].show;
    },
    openAccordionWithUrl(index) {
      this.FAQs[index].show = true;
    },
  },
};
</script>
